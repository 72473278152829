import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ButtonLink from './ButtonLink';
import downloadFile from '../../static/Resume 5_1_23.pdf'
import wavesBreakingOnShore from '../../static/waves_break_on_shore.jpeg'

export default function ButtonAppBar() {
  return (
    <header>
      <Box sx={{ flexGrow: 1, minHeight: "95px", position: "relative", display: "block", marginBottom: '4em' }}>
        <AppBar position="static" sx={{ position: 'fixed', zIndex: 3 }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Kevin Coleman
            </Typography>

            <Button color="error" variant="contained" href={downloadFile} download>Resume</Button>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}