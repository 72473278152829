import * as React from "react"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ButtonLink from '../components/ButtonLink';
import Copyright from '../components/Copyright';
import Layout from '../components/Layout'
import MediaCard from '../components/MediaCard'
import CareerTimeline from '../components/CareerTimeline'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
const hideNewFeatures = true;

const Index = () => {
  return (
    <Layout pageTitle="Home">
      <Box sx={{
        bgcolor: '#fff',
      }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
              <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 2
              }}>
                <Grid container spacing={10} style={{ 
                  paddingLeft: '4em',
                  paddingRight: '4em',
                  paddingBottom: '2em' }}>
                  <Grid item sm={5} xs={12} style={{ justifyContent: 'center' }}>
                    <Box sx={{
                      boxShadow: 4
                    }}>
                      <img src={`traveling.jpeg`} style={{ width: '100%', display: 'block', boxShadow: 4, borderRadius: "8px" }}></img>
                    </Box>
                  </Grid>
                  <Grid item sm={7} xs={12} style={{ display: 'flex', justifyContent: 'center',flexDirection: 'column' }}>
                    <Typography variant="h4" component="div">
                      <b>Kevin Coleman</b>
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      Software Engineer, Surfer, Pet Dad
                    </Typography>

                    <Divider variant="middle" />

                    <Grid container spacing={1} >
                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" gutterTop>
                          <b>Location:</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Typography variant="caption" gutterTop>
                          Ann Arbor, MI
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" gutterTop>
                          <b>Email:</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Typography variant="caption" gutterTop>
                          kevin@kevincoleman.net
                        </Typography>
                      </Grid>


                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" gutterTop>
                          <b>Phone:</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Typography variant="caption" gutterTop>
                          714-580-2061
                        </Typography>
                      </Grid>

                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: "1em" }}>
                  <Typography variant="overline" gutterBottom component="div" style={{ justifyContent: 'center', padding: "2em" }}>
                    Highly skilled software engineer and architect with extensive experience in designing, developing, and implementing innovative solutions across healthcare, legal, and political industries. Expertise in Ruby on Rails, a multitude of Javascript frameworks, and various DevOps technologies, with a strong focus on system architecture, automation, and performance optimization. Proven track record of delivering scalable, data-driven applications, and implementing advanced data processing pipelines.
                  </Typography>
                  <Divider variant="middle" />                  
                  <Grid item xs={12} style={{ justifyContent: 'center', padding: "2em" }}>
                    
                    <Typography variant="h6" gutterBottom component="div">
                      Career Timeline:
                    </Typography>

                    <CareerTimeline />
                  </Grid>

                  <Divider variant="middle" />                  

                  <Grid item xs={12} style={{ justifyContent: 'center', padding: "2em" }}>
                    
                    <Typography variant="h6" gutterBottom component="div">
                      Projects:
                    </Typography>
                    <Card sx={{ minWidth: 275, margin: "1em" }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          App
                        </Typography>
                        <Typography variant="h5" component="div">
                          Recipio
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          2020-Present
                        </Typography>
                        <Typography variant="body2">
                          Created a user-friendly application for storing, organizing, and managing recipe links and shopping lists.
                          Integrated AI technologies to intelligently categorize recipes and generate shopping lists based on user preferences.
                          Developed a RESTful API to facilitate seamless interaction between the frontend and backend.
                          Continuously improved the app based on user feedback and analytics, ensuring a high-quality user experience.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="outlined" href="https://recipio.app">Learn More</Button>
                      </CardActions>
                    </Card>

                    <Card sx={{ minWidth: 275, margin: "1em" }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          App
                        </Typography>
                        <Typography variant="h5" component="div">
                          StudentIdeals
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          2015-2020
                        </Typography>
                        <Typography variant="body2">
                          Developed a full-stack Ruby on Rails, PostgreSQL, and React application that helped students discover discounts at businesses near them.
                          Implemented geolocation features to display relevant deals based on user's location.
                          Designed and optimized database schema to efficiently store and retrieve discount information.
                          Utilized responsive web design principles to ensure a seamless user experience on various devices.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="outlined" href="https://studentideals.com">Learn More</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item sm={12}>
                  <Box sx={{
                    bgcolor: 'primary.main',
                    p: 2,
                    mt: 2
                  }}>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a href="https://twitter.com/kevinjcoleman7" style={{ color: 'white' }}><TwitterIcon /></a>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a href="https://github.com/kevinjcoleman" style={{ color: 'white' }}><GitHubIcon /></a>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a href="https://www.linkedin.com/in/kevinjamescoleman/" style={{ color: 'white' }}><LinkedInIcon /></a>
                      </Grid>

                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a href="https://www.instagram.com/kevinjcoleman7/" style={{ color: 'white' }}><InstagramIcon /></a>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
          </Grid>

          { hideNewFeatures ? null : (
            <Grid item xs={12} style={{ justifyContent: 'center' }}>
              <Typography variant="h6" gutterBottom component="div">
                In my free time I like:
              </Typography>

              <Grid item xs={6} style={{ justifyContent: 'center' }}>
                <MediaCard imageSrc="hiking.jpeg" title="Hiking" />
              </Grid>

              <Grid item xs={6} style={{ justifyContent: 'center' }}>
                <MediaCard imageSrc="traveling.jpeg" title="Traveling" />
              </Grid>

              <Grid item xs={6} style={{ justifyContent: 'center' }}>
                <MediaCard imageSrc="wedding.jpeg" title="Hanging out with my wife" />
              </Grid>

              <Grid item xs={6} style={{ justifyContent: 'center' }}>
                <MediaCard imageSrc="finn.jpeg" title="Training my dog Finn" />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Layout>
  )
}

export default Index