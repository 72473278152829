import * as React from 'react'
import { container } from './layout.module.css'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Copyright from './Copyright';
import AppBar from './AppBar';


const Layout = ({ pageTitle, children }) => {
  return (
    <React.Fragment className={container}>
      <AppBar />

      <div style={{ position: 'relative', zIndex: 2 }}>
        <Container>
          {children}
        </Container>
      </div>
      <Copyright />
    </React.Fragment>
  );
}

export default Layout