import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Link from '@mui/material/Link';

export default function OutlinedTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          2020-Present
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Staff Software Engineer, <Link href="https://www.hellobrightline.com/" variant="overline" underline="hover">
            Brightline
          </Link>
        </TimelineContent>        
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          2020-2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Senior Software Engineer/Engineering Manager, <Link href="https://cerebral.com/" variant="overline" underline="hover">
            Cerebral
          </Link>
        </TimelineContent>        
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          2017-2020
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary" />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          Senior Software Engineer, <Link href="https://legalnature.com/" variant="overline" underline="hover">
            LegalNature
          </Link>
        </TimelineContent>      
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          2014-2017
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Services Software Engineer, <Link href="https://nationbuilder.com/" variant="overline" underline="hover">
            NationBuilder
          </Link>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          2010-2014
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="secondary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Bachelor of Arts, <Link href="https://www.oxy.edu/" variant="overline" underline="hover">
            Occidental College
          </Link>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}